import { authedFetch } from '@agnet-aviation/auth'
import { API_ENDPOINTS, API_URL } from '../constants/constants'

interface StaticConfig {
  API_URL: string
  SOCKET_URL: string
  IMPORT_MAP_URL: string
}

interface PublicConfig {
  KEYCLOAK_API_URI: string
  IDP_PROVIDER: string
}

interface PrivateConfig {
  AGNET_PLATFORM_URL: string
  AGNET_PLATFORM_VOIP_HOST_URL: string
  AGNET_PLATFORM_VOIP_SIP_URL: string
  AGNET_TOKEN_URL: string
  ONESIGNAL_APP_ID: string
  ONESIGNAL_SAFARI_ID: string
  IDP_CLIENT_ID: string
  AGNET_KEYCLOAK_SECRET: string
}

type Config = StaticConfig & PublicConfig & PrivateConfig

declare global {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Window extends Config {}
}

interface PrivateApiConfig {
  socketUrl?: string
  oneSignalAppId?: string
  oneSignalSafariId?: string
  agnetPlatformUrl?: string
  agnetPlatformVoipHostUrl?: string
  agnetPlatformVoipSipUrl?: string
  agnetTokenUrl?: string
  agnetKeycloakSecret?: string
  idpClientId?: string
}

interface PublicApiConfig {
  keycloakAddress?: string
  idpProvider?: string
  oneSignalAppId?: string
  oneSignalSafariId?: string
}

const isNonEmptyString = (value?: unknown): value is string =>
  typeof value === 'string' && value.length > 0

export const setPublicConfig = async () => {
  const publicConfig = await fetch(`${API_URL}/${API_ENDPOINTS.CONFIG_PUBLIC}`)
  const config: PublicApiConfig = await publicConfig.json()

  const { idpProvider, oneSignalAppId, oneSignalSafariId } = config

  if (!isNonEmptyString(idpProvider)) {
    throw new Error(`Fatal config error: Invalid IDP Provider. Got: ${idpProvider}`)
  } else {
    window.IDP_PROVIDER = idpProvider
  }

  if (!isNonEmptyString(oneSignalAppId)) {
    console.error(`Config error: Invalid OneSignal App ID. Got: ${oneSignalAppId}`)
  } else if (!window.ONESIGNAL_APP_ID) {
    window.ONESIGNAL_APP_ID = oneSignalAppId
  }

  if (!isNonEmptyString(oneSignalSafariId)) {
    console.error(`Config error: Invalid OneSignal Safari ID. Got: ${oneSignalSafariId}`)
  } else if (!window.ONESIGNAL_SAFARI_ID) {
    window.ONESIGNAL_SAFARI_ID = oneSignalSafariId
  }
}

export const setPrivateConfig = async () => {
  const config: PrivateApiConfig = await authedFetch(`${API_URL}/${API_ENDPOINTS.CONFIG_PRIVATE}`)

  const {
    socketUrl,
    agnetPlatformUrl,
    agnetPlatformVoipHostUrl,
    agnetPlatformVoipSipUrl,
    agnetTokenUrl,
    agnetKeycloakSecret,
    idpClientId,
  } = config

  if (!isNonEmptyString(socketUrl)) {
    console.error(`Config error: Invalid socket URL. Got: ${socketUrl}`)
  } else {
    window.SOCKET_URL = socketUrl
  }

  if (!isNonEmptyString(agnetPlatformUrl)) {
    console.error(`Config error: Invalid Agnet Platform URL. Got: ${agnetPlatformUrl}`)
  } else {
    window.AGNET_PLATFORM_URL = agnetPlatformUrl
  }

  if (!isNonEmptyString(agnetPlatformVoipHostUrl)) {
    console.error(
      `Config error: Invalid Agnet Platform VoIP Host URL. Got: ${agnetPlatformVoipHostUrl}`
    )
  } else {
    window.AGNET_PLATFORM_VOIP_HOST_URL = agnetPlatformVoipHostUrl
  }

  if (!isNonEmptyString(agnetPlatformVoipSipUrl)) {
    console.error(
      `Config error: Invalid Agnet Platform VoIP SIP URL. Got: ${agnetPlatformVoipSipUrl}`
    )
  } else {
    window.AGNET_PLATFORM_VOIP_SIP_URL = agnetPlatformVoipSipUrl
  }

  if (!isNonEmptyString(agnetTokenUrl)) {
    console.error(`Config error: Invalid Agnet Token URL. Got: ${agnetTokenUrl}`)
  } else {
    window.AGNET_TOKEN_URL = agnetTokenUrl
  }

  if (!isNonEmptyString(agnetKeycloakSecret)) {
    console.error(`Config error: Invalid Agnet Keycloak Secret. Got: ${agnetKeycloakSecret}`)
  } else {
    window.AGNET_KEYCLOAK_SECRET = agnetKeycloakSecret
  }

  if (!isNonEmptyString(idpClientId)) {
    console.error(`Config error: Invalid IDP Client ID. Got: ${idpClientId}`)
  } else {
    window.IDP_CLIENT_ID = idpClientId
  }
}
